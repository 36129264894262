import Rodal from 'rodal'
import { Button as ReactButton, Col, Container, Row, ModalHeader, ModalBody, ListGroup, ListGroupItem, UncontrolledTooltip } from 'reactstrap'
import Switch from 'react-switch'
import Select from 'react-select'
import { useSelector } from 'react-redux'

import Button from '../../../Forms/Fields/button'
import IconSet from '../../../icon'
import { checkPermission, cms, editCMS, getButtonActionByIcon, tidyName } from '../../../../helper'
import React from 'react'

const GridButton = ({
  grid_buttons,
  getDefaultRowOption,
  mainGrid,
  gridSelectedRow,
  setting,
  triggerButtonActions,
  gridFilters,
  currentTabData,
  updatePreferenceModal,
  updateTempPreferenceColumns,
  tempPreferenceColumns,
  visiblePreference,
  preferenceColumns,
  UpdatePreferenceColumn,
  parentPageName,
  pageName,
  isTab,
  isModel,
  recPerPage,
  setRecPerPage,
  set_email_modal_open,
  content,
}) => {
  const editMode = useSelector((state) => state.CMS.editMode)

  const renderButtons = (buttons) => {
    const defaultRowCountOption = getDefaultRowOption()

    // const currentTabData = page_data?.component_call_buttons.filter(
    //   (item) => item.component_call_form[0]?.main_grid === mainGrid || item.component_call_form[0]?.restore_grid === mainGrid
    // )
    let grid_button_setting = mainGrid?.grid_button_setting

    let grid_button
    if (grid_button_setting) {
      grid_button = JSON.parse(grid_button_setting)?.mainGrid
    }
    const viewButtons = buttons.map((item, key) => {
      const isButtonHide = grid_button && grid_button[item?.name] ? true : false

      if (getButtonActionByIcon(item?.icon) && !checkPermission(getButtonActionByIcon(item?.icon), pageName, parentPageName, isTab, true)) {
        return
      }

      if (!isButtonHide) {
        if ((content?.grid_settings?.display_report_type?.length === 0 || content?.grid_email_report?.length === 0) && item?.name === 'mail_button')
          return
        return (
          <Button
            item={item}
            key={key}
            gridSelectedRow={gridSelectedRow}
            triggerActions={(action) => triggerButtonActions(action, gridFilters, gridSelectedRow, currentTabData.source_table, mainGrid)}
            onClickButton={() => {
              if (item?.component_name === 'mail_grid_report') {
                set_email_modal_open(true)
              }
            }}
          />
        )
      }
    })

    if (mainGrid.component_call_preferences_form.fields_order && setting) {
      viewButtons.push(
        <React.Fragment key={'preferance'}>
          <ReactButton
            id={'cms_preferqnce'}
            className='border-0 btn-icon btn-icon-only fsize-1 m-2'
            onClick={() => {
              if (editMode) {
                editCMS(setting.records_before_cms_value)
              } else {
                updatePreferenceModal(true)
              }
            }}>
            <IconSet icon={setting.preferences_icon} type={setting.preferences_icon_type} fontWeight={400} />
          </ReactButton>
          <UncontrolledTooltip placement='bottom' target={'cms_preferqnce'}>
            {cms(setting.cms_preferences_header_name)}
          </UncontrolledTooltip>
          <Rodal
            visible={visiblePreference}
            onClose={() => {
              updatePreferenceModal(false)
              updateTempPreferenceColumns(preferenceColumns)
            }}
            animation='zoom'
            showMask={false}
            disableBackdrop={true}
            width={500}>
            <ModalHeader>
              <span onClick={() => editCMS(setting.cms_preferences_header_name)}>{cms(setting.cms_preferences_header_name)}</span>
              <br />
              <small onClick={() => editCMS(setting.cms_preferences_sub_header_name)}>{cms(setting.cms_preferences_sub_header_name)}</small>
            </ModalHeader>
            <ModalBody>
              <br />

              <Container>
                <Row>
                  <Col>
                    <ListGroup>
                      <ListGroupItem active>{'Columns Setting'}</ListGroupItem>
                      <table className='table'>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Visible</th>
                            {/* <th>Print</th>
                            <th>Export</th> */}
                          </tr>
                        </thead>

                        <tbody>
                          {mainGrid &&
                            tempPreferenceColumns &&
                            Object.keys(tempPreferenceColumns).map((key) => {
                              if (key && key?.length > 0 && key !== 'aggregate_column') {
                                return (
                                  <tr key={key}>
                                    <td> {tidyName(key)}</td>
                                    <td>
                                      <Switch
                                        checked={tempPreferenceColumns[key]?.display}
                                        onChange={(value) =>
                                          updateTempPreferenceColumns({
                                            ...tempPreferenceColumns,
                                            [key]: {
                                              ...tempPreferenceColumns[key],
                                              display: value,
                                            },
                                          })
                                        }
                                        uncheckedIcon={<i className='fa-thin fa-xmark switch-icon'></i>}
                                        checkedIcon={<i className='fa-thin fa-check switch-icon'></i>}
                                        className='d-inline'
                                      />
                                    </td>
                                  </tr>
                                )
                              }
                              if (key && key?.length > 0 && key === 'aggregate_column') {
                                return (
                                  <>
                                    {tempPreferenceColumns[key]?.column_options?.columns?.map((col) => {
                                      return (
                                        <tr key={col}>
                                          <td> {tidyName(col)}</td>
                                          <td>
                                            <Switch
                                              checked={!tempPreferenceColumns[key]?.preference_options?.includes(col)}
                                              onChange={(value) => {
                                                let preference_options
                                                if (tempPreferenceColumns[key]?.preference_options) {
                                                  preference_options = [...tempPreferenceColumns[key]?.preference_options]
                                                } else {
                                                  preference_options = []
                                                }

                                                if (!value) {
                                                  preference_options?.push(col)
                                                } else {
                                                  let i = preference_options?.indexOf(col)
                                                  preference_options?.splice(i, 1)
                                                }

                                                updateTempPreferenceColumns({
                                                  ...tempPreferenceColumns,
                                                  [key]: {
                                                    ...tempPreferenceColumns[key],
                                                    preference_options,
                                                  },
                                                })
                                              }}
                                              uncheckedIcon={<i className='fa-thin fa-xmark switch-icon'></i>}
                                              checkedIcon={<i className='fa-thin fa-check switch-icon'></i>}
                                              className='d-inline'
                                            />
                                          </td>
                                        </tr>
                                      )
                                    })}
                                  </>
                                )
                              }
                            })}
                        </tbody>
                      </table>
                    </ListGroup>
                    <br />
                    <ListGroup>
                      <ListGroupItem active>Select Default Row</ListGroupItem>
                      <ListGroupItem>
                        <Select
                          options={defaultRowCountOption}
                          value={{ value: recPerPage?.value, label: recPerPage?.value }}
                          onChange={(option) => {
                            setRecPerPage({
                              ...recPerPage,
                              value: option?.value,
                            })
                          }}
                        />
                      </ListGroupItem>
                    </ListGroup>
                    <>
                      <br />
                      <ReactButton
                        color='primary'
                        className='ladda-button btn btn-shadow btn-pill btn-wide btn-primary float-right'
                        onClick={() => UpdatePreferenceColumn()}>
                        Apply Changes
                      </ReactButton>
                      <ReactButton
                        color='primary'
                        className='ladda-button btn btn-shadow btn-pill btn-wide btn-focus float-right mr-3'
                        onClick={() => {
                          updatePreferenceModal(false)
                          updateTempPreferenceColumns(preferenceColumns)
                        }}>
                        Close
                      </ReactButton>
                      <div className='clearfix'></div>
                    </>
                  </Col>
                </Row>
              </Container>
              <br />
            </ModalBody>
          </Rodal>
        </React.Fragment>
      )
    }
    return viewButtons
  }

  return <>{renderButtons(grid_buttons)}</>
}

export default GridButton
