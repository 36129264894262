import { call, put, select } from 'redux-saga/effects'

import { updateCMS } from '../../../helper'
import { reduxLoad } from '../../Reducers'

import {
  clearLastDataConnectionData,
  closeModal,
  fetchCoreData,
  fetchUpdateApiData,
  getCMS,
  setCMS,
  setCoreData,
  setDomains,
  setLastDataConnectionData,
  setScheduleApiData,
  setUpdateApiData,
} from '../../Reducers/CMS'
import { setLoader } from '../../Reducers/loader'
import { setNotification } from '../../Reducers/notificationHandling'
import { setCurrentTabData } from '../../Reducers/page'
import { setQueueLoading } from '../../Reducers/queue'
import { fetchUser } from '../../Reducers/user'

import {
  requestCMS,
  requestCoreAPI,
  requestCMSUpdateValue,
  requestCMSSubmitApi,
  requestCommonApi,
  requestUpdateAPI,
  requestScheduleAPI,
  requestConnectApi,
  requestConnectTemplateApi,
  requestConnectTemplateRestoreApi,
  requestFreeApi,
  submitTopNavButtonApi,
  submitPage,
  submitPageContent,
  removeItemByName,
  removeTabByName,
} from '../requests/CMS'
import { store } from '../../Config/configureStore'

export function* fetchCMS(action) {
  try {
    yield put(setLoader(true))
    const response = yield call(requestCMS, action.cache)
    if (response.status === 200) {
      const newCms = {}
      console.log('cms response::::::::', response)
      // response?.data?.forEach((items) => {
      //   items.forEach((item) => {
      //     newCms[item.key] = item.value
      //   })
      // })
      yield put(setCMS(response?.data))
    } else {
      yield put(setNotification({ type: 'error', message: response.message, status: response.status }))
    }
    // yield put(setLoader(false))
  } catch (error) {
    yield put(setNotification({ type: 'error', message: error.message, status: error?.status }))
    yield put(setLoader(false))
  }
}

export function* fetchCoreAPI(action) {
  let cache = action?.cache?.data?.cache
  if (cache === undefined || cache === null) {
    cache = false
  }
  try {
    if (!action.cache.data?.loadWithoutLoader) {
      yield put(setLoader(true))
    }

    const response = yield call(requestCoreAPI, cache)
    if (response.status === 200) {
      yield put(setCoreData(response.data))
    } else {
      yield put(setNotification({ type: 'error', message: response.message, status: response?.status }))
    }
    yield put(setLoader(false))
  } catch (error) {
    yield put(setNotification({ type: 'error', message: error.message, status: error?.status }))
    yield put(setLoader(false))
  }
}

export function* callUpdateApi(action) {
  try {
    const updateApiData = store.getState().CMS.updateApiData
    const response = yield call(requestUpdateAPI, action.cache)
    console.log('update api data:::::::', response, updateApiData)
    if (response.status === 200) {
      yield put(setUpdateApiData(response.data))
      if (
        updateApiData?.version &&
        response.data?.version &&
        (updateApiData?.version?.backend_version !== response.data?.version?.backend_version ||
          updateApiData?.version?.frontend_version !== response.data?.version?.frontend_version)
      ) {
        yield put(reduxLoad())

        if (updateApiData?.version?.frontend_version !== response.data?.version?.frontend_version) {
          setTimeout(() => {
            window.location.reload()
          }, [5000])
        }
      }
    } else {
      // yield put(setNotification({ type: 'error', message: response.message, status: response.status }))
    }
  } catch (error) {
    // yield put(setNotification({ type: 'error', message: error.message, status: error.status }))
    yield put(setLoader(false))
  }
}

export function* callScheduleApi() {
  try {
    const state = yield select()
    const response = yield call(requestScheduleAPI)
    if (response.status === 200) {
      if (
        (state.CMS.scheduleApiData && state.CMS.scheduleApiData['update-api'] !== response.data['update-api']) ||
        state.CMS.scheduleApiData === undefined
      ) {
        yield put(fetchUpdateApiData())
      }
      yield put(setScheduleApiData(response.data))
    } else {
      yield put(setNotification({ type: 'error', message: response.message, status: response.status }))
    }
  } catch (error) {
    yield put(setNotification({ type: 'error', message: error.message, status: error.status }))
    yield put(setLoader(false))
  }
}

export function* handleCMSEditSubmit(action) {
  try {
    yield put(setLoader(true))
    const state = yield select()
    const response = yield call(requestCMSUpdateValue, state.CMS.cmsModalValue)
    if (response.data.status === 1) {
      const newCms = {}
      response.data.data.forEach((item) => {
        newCms[item.key] = item.value
      })
      yield put(setCMS(newCms))
      yield put(closeModal())
    } else {
      yield put(setNotification({ type: 'error', message: 'Something went wrong try again later.', status: response.status }))
    }
    yield put(setLoader(false))
  } catch (error) {
    yield put(setNotification({ type: 'error', message: error.message, status: error.status }))
    yield put(setLoader(false))
  }
}

export function* handleSubmitApi(action) {
  let loader = true
  if (action?.data?.noLoad) {
    loader = false
  }
  try {
    if (loader) {
      yield put(setLoader(true))
    }
    const response = yield call(requestCMSSubmitApi, action.data)
    if (response.data.status === 1) {
      yield put(setNotification({ type: 'success', message: response.data.message, status: response.status }))
    } else {
      yield put(setNotification({ type: 'error', message: 'Something went wrong try again later.', status: response.status }))
    }
    if (loader) {
      yield put(setLoader(false))
    }
  } catch (error) {
    yield put(setNotification({ type: 'error', message: error.message, status: error.status }))
    if (loader) {
      yield put(setLoader(false))
    }
  }
}

export function* handleSubmitCommonApi(action) {
  try {
    yield put(setLoader(true))
    const response = yield call(requestCommonApi, action.data)
    if (
      (response.data.status === 1 || response.data.success === true || response.data.status === 200 || response.data.status === 202) &&
      response.status !== 200
    ) {
      yield put(reduxLoad())
      yield put(setNotification({ type: 'success', message: response.data.message, status: response.status }))
    } else if (response.status === 200) {
      yield put(setNotification({ type: 'success', message: response.data.message, status: response.status }))
      // window.open(response)
    } else if (response.status === 422) {
      yield put(setNotification({ type: 'error', message: response.data.message, errors: response.data.errors, status: response.data.status }))
    } else {
      yield put(setNotification({ type: 'error', message: 'Something went wrong try again later.', status: response.status }))
    }
    yield put(setLoader(false))
  } catch (error) {
    yield put(setNotification({ type: 'error', message: error.message, status: error.status }))
    yield put(setLoader(false))
  }
}

export function* handleCoreApiUpdate(action) {
  yield put(setCoreData(action.coreData))
}

export function* handleSubmitApiString(action) {
  try {
    yield put(setLoader(true))
    const response = yield call(requestCommonApi, action.data)
    if (response.data.status === 1) {
      yield put(fetchUpdateApiData(false))
      yield put(setNotification({ type: 'success', message: response.data.message, status: response.status }))
    } else if (response.status === 200 && response.data) {
    } else if (response.status === 422) {
      yield put(setNotification({ type: 'error', message: response.data.message, errors: response.data.errors, status: response.data.status }))
    } else {
      yield put(setNotification({ type: 'error', message: 'Something went wrong try again later.', status: response.status }))
    }
    yield put(setLoader(false))
  } catch (error) {
    yield put(setNotification({ type: 'error', message: error.message, status: error.status }))
    yield put(setLoader(false))
  }
}

export function* handleConnectApi(action) {
  try {
    yield put(action.data.isQueue ? setQueueLoading(true) : setLoader(true))
    yield put(clearLastDataConnectionData())
    const response = yield call(requestConnectApi, action.data)
    if (action.data.url === 'database-list') {
      yield put(setDomains(response.data.data))
    } else if (response.data && response.status === 200) {
      yield put(setLastDataConnectionData({ data: response.data, updatedAt: new Date(), name: action.data.name }))
    }
    if (response.data && response.status === 404) {
      let message = response.data.message
      message = message.substring(message.indexOf('[') + 1, message.lastIndexOf(']'))
      yield put(setLastDataConnectionData({ notFound: true, updatedAt: new Date(), name: action.data.name, notFoundMessage: message }))
    }
    yield put(action.data.isQueue ? setQueueLoading(false) : setLoader(false))
  } catch (error) {
    yield put(setNotification({ type: 'error', message: error.message, status: error.status }))
    yield put(action.data.isQueue ? setQueueLoading(false) : setLoader(false))
  }
}
export function* handleConnectTemplateApi(action) {
  try {
    if (!action.data.noLoad) {
      yield put(action.data.isQueue ? setQueueLoading(true) : setLoader(true))
    }
    yield put(clearLastDataConnectionData())
    const response = yield call(requestConnectTemplateApi, action.data)

    if (response.data && response.status === 200) {
      yield put(
        setLastDataConnectionData({
          data: response.data,
          updatedAt: new Date().toLocaleString('en-GB', { timeZone: 'Europe/London' }),
          name: action.data.name,
          main: action.data.main !== false,
        })
      )
    }
    if (response.data && response.status === 404) {
      let message = response.data.message
      message = message.substring(message.indexOf('[') + 1, message.lastIndexOf(']'))
      yield put(
        setLastDataConnectionData({
          notFound: true,
          updatedAt: new Date().toLocaleString('en-GB', { timeZone: 'Europe/London' }),
          name: action.data.name,
          notFoundMessage: message,
        })
      )
    }
    if (response.data && response.status === 500) {
      let message = response.data.message

      yield put(setNotification({ type: 'error', message: message }))
    }
    yield put(action.data.isQueue ? setQueueLoading(false) : setLoader(false))
  } catch (error) {
    yield put(setNotification({ type: 'error', message: error.message, status: error.status }))
    yield put(action.data.isQueue ? setQueueLoading(false) : setLoader(false))
  }
}
export function* handleConnectTemplateRestoreApi(action) {
  try {
    yield put(action.data.isQueue ? setQueueLoading(true) : setLoader(true))
    yield put(clearLastDataConnectionData())
    const response = yield call(requestConnectTemplateRestoreApi, action.data)
    if (response.data && response.status === 200) {
      yield put(setLastDataConnectionData({ data: response.data, updatedAt: new Date(), name: action.data.name }))
    }
    if (response.data && response.status === 404) {
      let message = response.data.message
      message = message.substring(message.indexOf('[') + 1, message.lastIndexOf(']'))
      yield put(setLastDataConnectionData({ notFound: true, updatedAt: new Date(), name: action.data.name, notFoundMessage: message }))
    }
    yield put(action.data.isQueue ? setQueueLoading(false) : setLoader(false))
  } catch (error) {
    yield put(setNotification({ type: 'error', message: error.message, status: error.status }))
    yield put(action.data.isQueue ? setQueueLoading(false) : setLoader(false))
  }
}

export function* handleFreeApi(action) {
  try {
    yield put(setLoader(true))
    const response = yield call(requestFreeApi, action.data)
    if (action.data && action.data.saveTabData) {
      yield put(setCurrentTabData(response.data.data))
    }
    yield put(setLoader(false))
  } catch (error) {
    yield put(setNotification({ type: 'error', message: error.message, status: error.status }))
    yield put(setLoader(false))
  }
}

export function* handleSubmitRequestApi(action) {
  try {
    if (action.data.queue) {
      yield put(setQueueLoading(true))
    }
    if (action.data.load) {
      yield put(setLoader(true))
    }
    const response = yield call(requestFreeApi, action.data)
    yield put(fetchUpdateApiData())

    if (response.status === 202) {
      yield put(
        setNotification({
          type: 'success',
          message: response.data.message ? response.data.message : 'Your request has been updated!',
          status: response.status,
        })
      )

      if (!action.data.queue && action.data && !action.data.noReload) {
        yield put(fetchCoreData(action))
        if (action?.data?.load === false) {
          yield put(fetchUser(false))
        } else {
          yield put(fetchUser(true))
        }
      }
      if (action.data.scheduleApiCall) {
        yield put(fetchUpdateApiData())
      }
    }
    if (response.status === 400) {
      yield put(
        setNotification({
          type: 'error',
          message: response.data.message ? response.data.message : 'Something went wrong try again later.',
          status: response.status,
        })
      )
    }
    if (action.data.queue) {
      yield put(setQueueLoading(false))
    }
    if (action.data.load) {
      yield put(setLoader(false))
    }
  } catch (error) {
    yield put(setNotification({ type: 'error', message: error.message, status: error.status }))
  }
}

export function* handleTopNavButton(action) {
  try {
    const response = yield call(submitTopNavButtonApi, action.data)
    if (response.status === 202) {
      yield put(setNotification({ type: 'success', message: 'Top nav button updated!', status: response.status }))
      yield put(fetchCoreData(false))
    }
    if (response.status === 400) {
      yield put(setNotification({ type: 'error', message: 'Something went wrong try again later.', status: response.status }))
    }
  } catch (error) {
    yield put(setNotification({ type: 'error', message: error.message, status: error.status }))
  }
}

export function* handleRemoveItemByName(action) {
  try {
    const response = yield call(removeItemByName, action.data)
    if (response.status === 202) {
      yield put(setNotification({ type: 'success', message: 'Page button deleted!', status: response.status }))
      yield put(fetchCoreData(false))
    } else {
      yield put(setNotification({ type: 'error', message: 'Something went wrong try again later.', status: response.status }))
    }
  } catch (error) {
    yield put(setNotification({ type: 'error', message: error.message, status: error.status }))
  }
}
export function* handleRemoveTabByName(action) {
  try {
    const response = yield call(removeTabByName, { name: action.data.name, page_name: action.data.page_name })
    if (response.status === 202) {
      yield put(setNotification({ type: 'success', message: 'Page button deleted!', status: response.status }))
      yield put(fetchCoreData(false))
    } else {
      yield put(setNotification({ type: 'error', message: 'Something went wrong try again later.', status: response.status }))
    }
  } catch (error) {
    yield put(setNotification({ type: 'error', message: error.message, status: error.status }))
  }
}

export function* handleSubmitPage(action) {
  try {
    const response = yield call(submitPage, action.data)
    if (response.status === 202) {
      yield put(setNotification({ type: 'success', message: 'Page updated!', status: response.status }))
      yield put(fetchCoreData(false))
      yield put(fetchUser())
      yield put(getCMS(false))
    }
    if (response.status === 400) {
      yield put(setNotification({ type: 'error', message: 'Something went wrong try again later.', status: response.status }))
    }
  } catch (error) {
    yield put(setNotification({ type: 'error', message: error.message, status: error.status }))
  }
}

export function* handleSubmitPageContent(action) {
  try {
    const response = yield call(submitPageContent, action.data)
    if (response.status === 202) {
      yield put(setNotification({ type: 'success', message: 'Page updated!', status: response.status }))
      yield put(fetchCoreData(false))
      yield put(getCMS(false))
    }
    if (response.status === 400) {
      yield put(setNotification({ type: 'error', message: 'Something went wrong try again later.', status: response.status }))
    }
  } catch (error) {
    yield put(setNotification({ type: 'error', message: error.message, status: error.status }))
  }
}
